<template>
    <div class="relative w-full">
        <ul v-if="showSuggestions && suggestions.length" class="absolute z-10 w-full mb-1 bg-white border border-gray-300 rounded shadow bottom-full">
            <li
                v-for="(user, index) in suggestions"
                :key="user.encid"
                @click="selectUser(user)"
                :class="['suggestion-item', { 'bg-gray-100': index === selectedIndex }]"
                class="px-4 py-2 cursor-pointer hover:bg-gray-200"
            >
                {{ user.name }}
            </li>
        </ul>
        <input
            type="text"
            ref="messageInput"
            v-model="inputValue"
            @input="handleInput"
            @keydown="handleKeyDown"
            :placeholder="$t(inputPlaceholder)"
            :class="inputClass"
        />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import axios from 'axios';

const props = defineProps({
    // value: String,
    inputClass: {
        type: String,
        default: ''
    },
    inputPlaceholder: {
        type: String,
        default: 'Type a message...'
    }
});
const inputValue = defineModel({
    type: String,
    required: true,
});

const emits = defineEmits(['input', 'mention', 'submit']);

/*watch(inputValue, (newVal) => {
    emits('input', newVal); // Emit input event for v-model support
});*/


const messageInput = ref(null);
const users = ref([]);
const suggestions = ref([]);
const showSuggestions = ref(false);
const mentionQuery = ref('');
const selectedIndex = ref(-1);
const mentions = ref([]);

const fetchUsers = async (query) => {
    try {
        const response = await axios.get(route('load.mention.users'), {
            params: { query }
        });
        users.value = response.data.map(user => ({
            encid: user.encid,
            name: `${user.firstname} ${user.lastname}`
        }));
        suggestions.value = users.value;
        showSuggestions.value = true;
    } catch (error) {
        console.error('Failed to fetch users', error);
    }
};

const handleInput = (event) => {
    const cursorPos = event.target.selectionStart;
    const beforeCursor = inputValue.value.slice(0, cursorPos);
    const mentionMatch = beforeCursor.match(/@([a-zA-Z0-9_]+)?$/);

    if (mentionMatch) {
        mentionQuery.value = mentionMatch[1];
        fetchUsers(mentionQuery.value);
    } else {
        showSuggestions.value = false;
    }
};

const handleKeyDown = (event) => {
    if (showSuggestions.value) {
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            selectedIndex.value = (selectedIndex.value > 0) ? selectedIndex.value - 1 : suggestions.value.length - 1;
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            selectedIndex.value = (selectedIndex.value < suggestions.value.length - 1) ? selectedIndex.value + 1 : 0;
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (selectedIndex.value >= 0 && selectedIndex.value < suggestions.value.length) {
                selectUser(suggestions.value[selectedIndex.value]);
            }
        }
    } else if (event.key === 'Enter') {
        console.log('emit submit');
        emits('submit');
    }
};

const selectUser = (user) => {
    const cursorPos = messageInput.value.selectionStart;
    const beforeCursor = inputValue.value.slice(0, cursorPos);
    const afterCursor = inputValue.value.slice(cursorPos);
    const mentionMatch = beforeCursor.match(/@([a-zA-Z0-9_]+)?$/);

    if (mentionMatch) {
        const startIndex = mentionMatch.index;
        inputValue.value = beforeCursor.slice(0, startIndex) + `@${user.name} ` + afterCursor;

        mentions.value.push({ username: user.name, userEncid: user.encid });
        showSuggestions.value = false;
    }

    emits('mention', mentions.value);  // Emit mentions array to parent
};
</script>
